@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .visible {
    visibility: visible;
  }
  .invisible {
    visibility: hidden;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.glass {
  background: rgba(207, 207, 207, 0.26);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 1000;
}

html {
  scroll-behavior: smooth;
}
@media screen and (0px < width < 426px) {
  .hide-in-md{
    display: none;
  }
 
}
