/* .main-container {
  margin-left: 302px;
  margin-top: 51px;
  padding: 0;
 
} */

.task-item-details-header input {
  width: 100%;
}

.task-header {
  box-shadow: 0 0 0.5rem rgb(203, 203, 203);
  margin-bottom: 1rem;
}
.task-header > h3 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.7rem;
}
.flex-ver {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.item-center {
  display: grid;
  place-content: center;
}
.task-header-action-wrapper {
  width: fit-content;
  gap: 1.5rem;
}
.task-header-action-item {
  gap: 0.5rem;
  color: gray;
  cursor: pointer;
}

.task-wrapper {
  margin-inline: 1rem;
  /* background: repeating-linear-gradient(
    0deg,
    red,
    red 2px,
    white 2px,
    white 87px
  ); */
  /* height: 100vh; */
  /* height: calc(100vh - 155px); */
  overflow: auto;
  /* border: 1px red solid; */
}
.task-item {
  padding: 1rem 0;
  transition: 0.3s all;
  cursor: pointer;
  border-bottom: 1px gainsboro solid;
}

.task-item:hover {
  background-color: #f1f9ff;
  transition: 0.4s all;
}
.task-item:active {
  background-color: #e2f0fb;
  transition: 0.1s all;
}
.task-item.task-item-add:hover {
  background-color: #eaeaea !important;
}
.task-item.task-item-add:active {
  background-color: #edf4f9 !important;
  transition: 0.1s all;
}

.task-item-deatils-short p {
  font-size: 14px;
  margin-bottom: 0;
  color: rgb(96, 96, 96);
}
.task-item-chip-wrapper {
  /* max-width: 275px; */
  text-overflow: ellipsis;
  overflow: auto;
  border-radius: 7px;
}
.task-item-chip-wrapper::-webkit-scrollbar {
  /* width: 100px; */
  height: 2px;
}
.task-item-chip {
  background-color: #eaeaea;
  padding: 0.1rem 0.3rem;
  margin-right: 0.4rem;
  border-radius: 7px;
  white-space: nowrap;
}

.task-item-action {
  width: 80px;
}
.task-item-action i.fa-circle,
.task-item-action i.fa-check-circle {
  color: #00b4b4 !important;
}
.task-item-info {
  flex: 1;
  display: grid;
  align-content: center;
}
.task-item-title {
  position: relative;
  font-size: 16px;
}
.task-item-priority {
  font-size: 14px;
  position: absolute;
  right: -80px;
  top: -10px;
  width: 120px;
}
.task-item-subtitle {
  font-size: 14px;
  color: rgb(96, 96, 96);
}
i {
  transition: 0.1s;
  transform-origin: center;
}
.fa-star:active,
.fa-circle:active,
.fa-check-circle:active {
  transform: scale(0.93);
}
.fa-star.fas {
  color: #09538b;
}

.task-list-page {
  display: flex;
}
.task-list-wrapper {
  flex: 1;
}
.task-item-details {
  min-width: 240px;
  max-width: 450px;
  background-color: whitesmoke;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  padding-bottom: 2.4rem;
}
.task-item-details > div:not(.task-item-details-footer) {
  background: white;
  padding: 1rem;
  border-radius: 6px;
}
.task-item-details > .task-item-details-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  align-items: center;
  padding: 1rem !important;
  background-color: white !important;
}

.task-searcher input {
  border-bottom: 1px transparent solid !important;
}
.task-searcher input:focus {
  border-bottom: 1px #09538b solid !important;
}

.task-item-details input[type="date"]::-webkit-inner-spin-button,
.task-item-details input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.reminder_item {
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  border: 1px transparent solid;
}
.reminder_item_hover:hover {
  border: 1px #bddcf4 solid;
}
.task-details-action-wrapper {
  text-align: end;
  padding: 0.5rem 1rem;
  position: sticky;
  width: 100%;
  height: 60px;
  background-color: white;
  border-top: 1px royalblue solid;
  bottom: 0;
  left: 0;
}
