@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  position: relative;
  /* zoom :75%   */
}

@font-face {
  font-family: "Gilroy";
  src: url("../src/public/fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/public/fonts/gilroy/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/public/fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
}
.montserrat {
  font-family: "Montserrat", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.gillory {
  font-family: "Gilroy", sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

:root {
  --prime: #fff;
  --sec: #109cf1;
  --ter: #d7edff;
  --four: #eef0f2;
  --fift: #000;
  --sixth: #323c47;
  --seven: #90a0b7;
  --eight: #f6f8f9;
  --ninth: #334d6e;
}

.newRow {
  /* overflow: scroll; */
  display: flex;
  justify-content: flex-start;
}

th,
td {
  display: block;
}

.btn {
  transition: all 1s ease-in;
}

.login-bg {
  background: #d1dbeb;
}

.text-clr {
  color: #072e8b;
}

.login-btn {
  background: #09d6e3;
}

.btn:hover {
  transform: scale(0.99);
}

/* utility clasess */
.bg-white {
  background: var(--prime) !important;
}

.bg-blue {
  background: var(--sec) !important;
}

.bg-light-blue {
  background: var(--ter) !important;
}

.bg-light-gray {
  background: var(--four) !important;
}

.bg-sec-gary {
  background: var(--seven) !important;
}

.bg-light-white {
  background: var(--eight) !important;
}

.bg-navi-blue {
  background: var(--ninth);
}

.bg-green {
  background-color: #cbefe8 !important;
}

.green {
  color: #167779 !important;
}

.bg-red {
  background-color: rgba(255, 169, 165, 0.6784313725) !important;
}

.red {
  color: #dd392a !important;
}

.white {
  color: var(--prime) !important;
}

.blue {
  color: var(--sec) !important;
}

.light-blue {
  color: var(--ter) !important;
}

.light-gary {
  color: var(--four) !important;
}

.dark {
  color: var(--fift) !important;
}

.gray {
  color: var(--sixth) !important;
}

.sec-gary {
  color: var(--seven);
}

.dark-blue {
  color: var(--ninth) !important;
}

.table-gary {
  color: #707683 !important;
}

/* navbar styling */
.top-navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 289px);
  height: 56px;
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
  z-index: 3;
}

.incOverviewTB > :not(caption) > * > * {
  border-bottom-width: 0;
}

.short-desc p {
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-col-grow {
  flex-grow: 3;
}

nav div {
  margin-left: 10px;
}

.side-navbar {
  position: fixed;
  top: 0;
  right: left;
  width: 300px;
  height: 100vh;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.45);
  z-index: 1;
  transition: all 0.5s ease-in-out;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side-navbar-div {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.side-navbar-div::-webkit-scrollbar {
  background: var(--prime);
  border-radius: 6px;
  width: 8px;
}

.side-navbar-div::-webkit-scrollbar-thumb {
  border-radius: 6px;
  cursor: pointer;
  background: rgb(230, 230, 230);
}

.user-info-box {
  position: absolute;
  top: -50%;
  left: 160%;
  z-index: 100;
  /* transition: all 0.5s ease-in; */
  width: 400px !important;
}

.user-info-box::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 5px;
  left: 0%;
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent white white;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}

/* input, */
.p-1.px-2.ms-2.d-inline.border.border-2.rounded {
  height: 36px !important;
}

.sub-items li {
  cursor: pointer;
  margin: 0.7rem 0 !important;
}

.sub-items {
  display: none;
}

.multiselect-width {
  width: 500px !important;
}

a {
  text-decoration: none !important;
}

.active-list {
  background: var(--ter);
  color: var(--ninth) !important;
  padding: 6px 5px 6px 10px;
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}

.active-sub-item {
  background: var(--four);
  padding: 4px 10px;
  border-radius: 6px;
}

.navbar-toggle {
  position: relative;
  left: 0;
  bottom: 0;
  z-index: 3 !important;
}

.sidenav-item {
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}

.sidenav-item-width {
  width: 0px !important;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

/* main space styling */
.dp-update {
  width: 45px;
  height: 45px !important;
  display: inline-block;
  margin: 0 !important;
  position: absolute;
  height: auto;
  border-radius: 50%;
  left: 0%;
  top: 0%;
  z-index: 99;
  opacity: 0;
}

.iconwrapper {
  position: absolute;
  left: 75%;
  width: 50px;
  top: 68%;
  height: 50px;
}

.camera-icon {
  font-size: 20px;
  border-radius: 50%;
  padding: 12px;
  width: 45px;
  height: 45px;
  text-align: center;
}

/* .main-container { */
  /* margin-top: 3em;
  padding: 2em;
  margin-left: 310px;
  transition: all 0.5s ease-in-out; */
/* } */

.my-profile-container {
  margin-top: 6em;
}

main {
  background: var(--prime);
  border-radius: 6px;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
}

.notes,
.Activity {
  border: 1px solid var(--seven);
  display: block;
  width: 100%;
  max-height: 200px;
  height: 150px;
  overflow-y: scroll;
  outline: none;
  resize: none;
  padding: 10px;
}

.notes {
  border-left: 5px solid #ffb946 !important;
}

.Activity {
  border-left: 5px solid #f49189 !important;
}

.hidden {
  display: none;
}

.taskTable {
  max-height: 500px !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

.notes::-webkit-scrollbar,
.taskTable::-webkit-scrollbar,
.Activity::-webkit-scrollbar {
  background: var(--prime);
  border-radius: 6px;
  width: 8px;
  height: 8px;
}

.notes::-webkit-scrollbar-thumb,
.Activity::-webkit-scrollbar-thumb {
  border-radius: 6px;
  cursor: pointer;
  background: var(--sec);
}

.logo-size {
  font-size: 1rem !important;
}

nav {
  transition: all 0.5s ease-in-out;
}

.navWidth {
  width: calc(100% - 84px);
}

.short-desc p {
  margin-bottom: 0 !important;
}

.drop-zone {
  width: 100%;
  height: 138px;
  border: 2px dashed #e6e6e6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachment-badge {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}

.sideNavWidth {
  width: 95px;
}

.main-ani {
  margin-left: 95px;
}

.rdw-image-wrapper,
.rdw-remove-wrapper {
  display: none !important;
}

.incTable {
  font-family: "Barlow", sans-serif;
  max-height: 450px;
}

.table-hover {
  margin-bottom: 0 !important;
}

.rdw-editor-toolbar {
  height: 90px;
  margin-bottom: 0px !important;
  border: 1px solid #ced4da !important;
}

.rdw-fontfamily-placeholder,
.rdw-dropdown-selectedtext {
  color: var(--fift) !important;
}

.attachment-wrapper {
  max-height: 150px !important;
  overflow-y: scroll;
  /* width: 200px !important; */
}

.rdw-editor-main {
  border: 1px solid #ced4da;
  padding-left: 10px;
  border-top: none;
}

.incTable::-webkit-scrollbar,
.rdw-editor-main::-webkit-scrollbar,
.updateLogs::-webkit-scrollbar,
.attachment-wrapper::-webkit-scrollbar {
  background: var(--prime);
  height: 15px !important;
  width: 10px;
}

.updateLogs {
  max-height: 600px;
  overflow-y: scroll;
  padding: 20px;
}

.rdw-editor-main::-webkit-scrollbar-thumb,
.updateLogs::-webkit-scrollbar-thumb,
.taskTable::-webkit-scrollbar-thumb,
.attachment-wrapper::-webkit-scrollbar-thumb,
.incTable::-webkit-scrollbar-thumb {
  background: rgb(230, 230, 230);
}

.sun-editor .se-toolbar {
  z-index: 1 !important;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.table-body tr,
.px-2.py-1.bg-light-gray.rounded {
  cursor: pointer;
}

/* progress bar styling */
.card {
  background: var(--prime);
  width: 160px !important;
  height: 100px;
  display: flex;
  margin: 5px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border: none !important;
}

.card .circle {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: default;
}

.card .circle .box,
.card .circle .box span {
  position: absolute;
  top: 50%;
  left: 50%;
}

.card .circle .box {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  transition: all 0.2s;
}

.card .circle:hover .box {
  transform: translate(-50%, -50%) scale(0.91);
}

.hide {
  transition: all 0.5s ease-in;
  display: none !important;
}

.circle .box span {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 600;
  transform: translate(-45%, -45%);
  transition: all 0.1s;
}

.card .circle:hover .box span {
  transform: translate(-45%, -45%) scale(1.09);
}

.card .text {
  font-size: 15px;
  font-weight: 600;
  color: var(--seven);
}

tr.border.inc-list:nth-child(odd) {
  background: var(--ter);
}

.disabled {
  cursor: not-allowed !important;
}

.incOverviewTable::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-overview {
  min-width: 400px !important;
  overflow: scroll;
}

.incOverviewTable::-webkit-scrollbar-thumb {
  background: var(--ninth);
}

.icons {
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translate(-50%, -50%);
}

.alert-style {
  z-index: 100;
}

.text-truncet {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  overflow: hidden;
}

.iconPassword {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(-50%, -50%);
}

.form-floating {
  position: relative;
}

.section {
  background: url("./public/img/bgImg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

#logOut {
  cursor: pointer !important;
}

.left-box {
  height: 100vh;
}

.useremail {
  text-transform: lowercase !important;
}

.iconPassword {
  cursor: pointer !important;
}

.rem-me-checkbox {
  width: 15px !important;
  height: 15px !important;
}

.react-date-picker__wrapper {
  height: 30px !important;
  border: none !important;
}

.updateLog {
  max-height: 500px;
  overflow-y: scroll;
}

.updateLog::-webkit-scrollbar {
  background: none;
  width: 8px !important;
}

.updateLog::-webkit-scrollbar-thumb {
  background: var(--ninth);
}

.prd-desc {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.prd-name {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 48px;
}

@media (min-width: 1080px) {
  .font.fs-6.fw-bold {
    font-size: 15px !important;
  }
}
.newSidenav {
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.45);
  z-index: 1;
  width: 300px;
  height: 100vh;
  position: fixed;
}

.smallNav {
  width: 94px !important;
}

.list td {
  padding: 0;
}

.list td a {
  width: 100%;
  display: block !important;
  height: 100%;
  padding: 0.5rem !important;
}

.emailConfigPass {
  position: absolute;
  top: 12%;
  right: 7%;
}

.table > :not(caption) > * > * {
  background-color: none;
}

.change_pass_icon {
  position: absolute;
  right: 3%;
  top: 19%;
}

.sidebar {
  position: fixed;
  height: 100vh;
  width: 302px;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  box-shadow: 2px 0px 2px 0 rgba(0, 0, 0, 0.45);
}

.create-incident__sidebar {
  margin: 0;
  padding: 1rem 0.2rem;
  height: 100vh;
  width: 302px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  /* change z-index if there somes stacking issue */
  font-size: 1.2rem;
  color: #001887;
  box-shadow: 4px 0px 4px 0 rgba(0, 0, 0, 0.2);
}

.create-incident__user {
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  font-weight: 300;
}

.create-incident__user-menu {
  margin-block: 0.4rem;
}

.create-incident__user-menu > * {
  padding: 0.1rem 0.4rem;
}

.create-incident__user-img {
  width: 50px;
  height: 50px;
}

.accordion-item__custom {
  background-color: transparent;
  border: none;
  font-weight: 700;
}

.accordion-button__custom {
  height: 50px;
  display: flex;
  align-items: center !important;
  gap: 0.8rem;
  background-color: rgba(235, 247, 255, 0.8) !important;
  padding: 0.6rem;
  color: #001887;
  border-radius: 8px !important;
  align-items: center;
  margin-bottom: 0.3rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.accordion-button__custom::after {
  background-image: url("./public/img/arrow.svg");
  position: absolute;
  top: 22px;
  right: 5px;
  transform: scale(0.8);
  transition: 0.3s !important;
  display: grid;
  place-content: center;
}

.accordion-button__custom:not(.collapsed)::after {
  background-image: url("./public/img/arrow.svg");
  transform: scale(0.8) rotateX(180deg);
  top: 10px;
  right: 5px;
  /* transform: scale(0.8); */
}

.create-incident__sidebar {
  overflow-y: scroll !important;
}

.create-incident__sidebar::-webkit-scrollbar {
  width: 8px;
}

.create-incident__sidebar::-webkit-scrollbar-thumb {
  display: none;
}

.accordion-button__custom:focus {
  box-shadow: none;
}

.accordion-button__custom:not(.collapsed) {
  color: inherit !important;
}

.accordion-button__custom-item::after {
  background-image: none !important;
}

.create-incident__user-details > * {
  margin: 0 !important;
  font-size: 1rem !important;
}

.accordion-button__custom-item span {
  display: inline-block;
  -webkit-margin-start: 2rem;
  margin-inline-start: 2rem;
}

.flex_and_margin0 {
  display: flex;
  gap: 0.4rem;
}

.flex_and_margin0 span {
  -webkit-margin-start: 0 !important;
  margin-inline-start: 0 !important;
  display: flex;
  gap: 1rem;
}

/* .Incident Sidbar Wrapper and Its Components.......................................#END# */
/* .Header TopBar Wrapper and Its Components.......................................#START# */
.header-wrapper {
  /* margin-left: 302px; */
  padding: 10px 20px;
  color: #109cf1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-image: url("./public/img/topbar.jpg"); */
  background-color: #fff;
  font-weight: 500;
  /* background-size: cover; */
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.1) !important;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  height: 51px;
  width: 100%;
}

.header-nav-list {
  display: flex;
  list-style-type: none;
  align-items: center;
  margin: 0;
}

.header-wrapper a {
  text-decoration: none;
  color: inherit;
}

.header-wrapper a:hover {
  color: inherit;
  text-shadow: 1px 0 0 white;
}

.header-title {
  margin: 0;
}

.header-title-wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.create-incident__user {
  color: #001887;
}

.create-incident__user:hover {
  color: inherit;
}

.helpLink {
  color: #001887;
}

.helpLink:hover {
  color: #001887 !important;
}

.rdw-emoji-wrapper {
  display: none !important;
}

/* ###################### Dashboard CSS #######################3 */
.dashboard-card {
  height: 165px;
  width: 30%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 10px;
  align-items: center;
  text-align: center;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

.card-shadow {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

/* ######################### Flow Designer ############################ */
.flow-top-bar {
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem 0.2rem;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #e4e4e4;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 2px;
}

/* ########################3 Landing Page ################################333 */
.pricing-card {
  box-shadow: 0px 7.78269px 43.5831px rgba(0, 0, 0, 0.05);
}

.testimonial-section {
  background: linear-gradient(
    180deg,
    rgba(236, 240, 253, 0) 0%,
    rgba(236, 240, 253, 0.53) 14.32%,
    #ecf0fd 45.83%,
    rgba(236, 240, 253, 0.43) 84.33%,
    rgba(236, 240, 253, 0) 100%
  );
}

.testimonial-card-shadow {
  box-shadow: 0px 13.5px 39.6403px rgba(215, 228, 249, 0.5);
}

.big-avatar {
  filter: drop-shadow(0px 10.5px 18px rgba(67, 48, 48, 0.14));
}

.small-avatar {
  filter: drop-shadow(0px 10.5px 18px rgba(0, 0, 0, 0.14));
}

.container {
  max-width: 1700px;
  margin: auto;
}

@media screen and (556px < width < 769px) {
  .test {
    align-items: stretch !important;
  }
  .test__imgs {
    flex: 0 1.5 auto;
    width: auto !important;
    height: auto !important;
    position: relative;
    z-index: 88;
  }
  .test__imgs > *,
  .test__imgs > * > * {
    flex: 0 1 auto;
    min-width: 0;
  }
  .test__cnt {
    flex: 0 1 547px;
    width: auto !important;
    height: auto !important;
    margin-inline: 0px !important;
    justify-content: center;
    width: 100% !important;
    height: auto !important;
    align-self: flex-start;
  }
  .test__cnt .testimonial-card-shadow {
    width: auto !important;
    height: auto !important;
    position: static !important;
  }
  .test__cnt__desc {
    width: 100% !important;
    height: 100% !important;
  }
}
.r {
  overflow: scroll;
  resize: both;
}

.contact-us-form__inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}

.contact-us-form__inputs__input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.contact-us-form__inputs__input > label {
  font-size: 14px;
  line-height: 12px;
  color: #616e7c;
}

.contact-us-form__inputs__input > input {
  border: none;
  align-self: stretch;
  padding: 0.4rem;
  background-color: white;
  width: 100%;
}

.contact-us-form__inputs__input.text-area {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  padding: 0.4rem;
}

.contact-us-form__inputs__input.text-area > textarea {
  background-color: white;
  width: 100%;
  padding: 0.4rem;
  border: none;
}

.contact-us-form__submit {
  all: initial;
  font-family: inherit;
  color: inherit;
  box-sizing: border-box;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding: 15px 27px;
  background: #020be9;
  margin-top: 58px;
}

.contact-us-form .error {
  border: 1px solid red;
  box-shadow: 0px 1px 3px 0px rgba(255, 0, 0, 0.4392156863);
}

.contact-us-form input:focus-visible,
.contact-us-form textarea:focus-visible {
  outline: none !important;
}

@media screen and (556px < width < 1024px) {
  .contact-us-form__inputs {
    gap: 34px;
    /* gap: calc(1vw * 4); */
  }
  .contact-us-form__inputs__input > label {
    font-size: 9px;
    line-height: 10px;
  }
  .contact-us-form__inputs__input {
    gap: 5px;
  }
  .contact-us-form__submit {
    all: initial;
    font-family: inherit;
    color: inherit;
    box-sizing: border-box;
    font-size: 11px;
    line-height: 12px;
    padding: 10px 19px;
    margin-top: 50px;
    background: #020be9;
    color: white;
  }
}
@media screen and (0 < width < 557px) {
  .contact-us-form__inputs {
    gap: 36px;
    /* gap: calc(1vw * 4); */
  }
  .contact-us-form__inputs__input > label {
    font-size: 14px;
    line-height: 12px;
  }
  .contact-us-form__inputs__input {
    gap: 8px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .contact-us-form__submit {
    all: initial;
    font-family: inherit;
    color: inherit;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 16px;
    padding: 15px 27px;
    margin-top: 58px;
    background: #020be9;
    color: white;
  }
}
.blue-g {
  background: linear-gradient(270deg, #3d47dd 24.07%, #0050dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.testi-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(236, 240, 253, 0) 0%,
    rgba(236, 240, 253, 0.53) 14.32%,
    #ecf0fd 45.83%,
    rgba(236, 240, 253, 0.43) 84.33%,
    rgba(236, 240, 253, 0) 100%
  );
  background: #e6efff;
}
.testi-wrapper .testi {
  margin: auto;
  padding: 109px 12px 115px;
  max-width: 890.25px;
  position: relative;
  z-index: 3;
}
.testi-wrapper .testi__header {
  margin-bottom: 34px;
}
.testi-wrapper .testi__header__icon {
  width: 37px;
  height: auto;
  aspect-ratio: 37/27;
  margin-bottom: 33px;
}
.testi-wrapper .testi__header__sub-heading {
  font-weight: 600;
  font-size: 18.9751px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #0c79cb;
  margin-bottom: 12px;
}
.testi-wrapper .testi__header__heading {
  font-weight: 700;
  font-size: 37.9502px;
  line-height: 46px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #1d1d1d;
  margin-bottom: 33px;
}
.testi-wrapper .testi__list {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.testi-wrapper .testi__list__person {
  margin-bottom: 18px;
}
.testi-wrapper .testi__list__person__feedback {
  font-weight: 400;
  font-size: 12.8px;
  line-height: 21px;
  text-align: center;
  color: #94a2b3;
  margin-bottom: 18px;
}
.testi-wrapper .testi__list__person__ratings {
  font-size: 21px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  gap: 4.5px;
}
.testi-wrapper .testi__list__person__ratings__given-star,
.testi-wrapper .testi__list__person__ratings__ungiven-star {
  font-size: inherit;
}
.testi-wrapper .testi__list__person__ratings__given-star {
  color: #0c79cb;
}
.testi-wrapper .testi__list__person__ratings__ungiven-star {
  color: #d9d9d9;
}
.testi-wrapper .testi__list__person__bio {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.testi-wrapper .testi__list__person__bio__img {
  width: 37px;
  height: auto;
  aspect-ratio: auto;
}
.testi-wrapper .testi__list__person__bio__details {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #453232;
}
.testi-wrapper .testi-wrapper-bg-1,
.testi-wrapper .testi-wrapper-bg-2 {
  position: absolute;
}
.testi-wrapper .testi-wrapper-bg-1 {
  top: 0;
  right: 0;
}
.testi-wrapper .testi-wrapper-bg-2 {
  left: 0;
  top: 12%;
}

@media screen and (818px < width < 1025px) {
  section.testi-wrapper .testi {
    padding: 77px 12px 81px;
    max-width: 633px !important;
  }
  section.testi-wrapper .testi__header {
    margin-bottom: 24px;
  }
  section.testi-wrapper .testi__header__icon {
    width: 26px;
    margin-bottom: 24px;
  }
  section.testi-wrapper .testi__header__sub-heading {
    font-size: 13.4934px;
    line-height: 17px;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
  }
  section.testi-wrapper .testi__header__heading {
    font-size: 26.9868px;
    line-height: 33px;
    letter-spacing: 0.003em;
  }
  section.testi-wrapper .testi__list__person {
    margin-bottom: 18px;
  }
  section.testi-wrapper .testi__list__person__feedback {
    font-size: 9.10222px;
    line-height: 15px;
    margin-bottom: 13px;
  }
  section.testi-wrapper .testi__list__person__ratings {
    font-size: 14px;
    margin-bottom: 18px;
    gap: 3.2px;
  }
  section.testi-wrapper .testi__list__person__ratings__given-star,
  section.testi-wrapper .testi__list__person__ratings__ungiven-star {
    font-size: inherit;
  }
  section.testi-wrapper .testi__list__person__bio {
    gap: 8px;
  }
  section.testi-wrapper .testi__list__person__bio__img {
    width: 26px;
  }
  section.testi-wrapper .testi__list__person__bio__details {
    font-weight: 600;
    font-size: 12.8px;
    line-height: 19px;
  }
  section.testi-wrapper .testi-wrapper-bg-1,
  section.testi-wrapper .testi-wrapper-bg-2 {
    position: absolute;
  }
  section.testi-wrapper .testi-wrapper-bg-1 {
    top: 0;
    right: 0;
    width: 285px;
    height: 169px;
  }
  section.testi-wrapper .testi-wrapper-bg-2 {
    left: 0;
    top: 12%;
    height: 286px;
  }
}
@media screen and (0 < width < 818px) {
  section.testi-wrapper .testi {
    padding: 105px 7px;
    max-width: 312px !important;
  }
  section.testi-wrapper .testi__header {
    margin-bottom: 16px;
  }
  section.testi-wrapper .testi__header__icon {
    width: 17px;
    margin-bottom: 16px;
  }
  section.testi-wrapper .testi__header__sub-heading {
    font-size: 9.09425px;
    line-height: 12px;
    letter-spacing: 0.01em;
    margin-bottom: 10px;
  }
  section.testi-wrapper .testi__header__heading {
    font-size: 18.1885px;
    line-height: 22px;
    letter-spacing: 0.003em;
  }
  section.testi-wrapper .testi__list__person {
    margin-bottom: 16px;
  }
  section.testi-wrapper .testi__list__person__feedback {
    font-size: 9px;
    line-height: 15px;
    margin-bottom: 14px;
  }
  section.testi-wrapper .testi__list__person__ratings {
    font-size: 10px;
    margin-bottom: 16px;
    gap: 2px;
  }
  section.testi-wrapper .testi__list__person__ratings__given-star,
  section.testi-wrapper .testi__list__person__ratings__ungiven-star {
    font-size: inherit;
  }
  section.testi-wrapper .testi__list__person__bio {
    gap: 6px;
  }
  section.testi-wrapper .testi__list__person__bio__img {
    width: 18px;
  }
  section.testi-wrapper .testi__list__person__bio__details {
    font-size: 8.62691px;
    line-height: 13px;
  }
  section.testi-wrapper .testi-wrapper-bg-1,
  section.testi-wrapper .testi-wrapper-bg-2 {
    display: none;
  }
}
